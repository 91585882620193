import React from 'react'
import styles from './index.module.scss'

import Layout from '../../components/Layout'
import { Helmet } from 'react-helmet'
import audioWave from '../../images/audio-wave.png'
import support from '../../images/support.svg'
import infinity from '../../images/infinity.svg'
import handshake from '../../images/handshake.svg'
import data from '../../images/dataexport.svg'
import history from '../../images/history.svg'
import GetStartedOrChat from '../../components/CTA/GetStartedOrChat'
import FAQ from '../../components/FAQ'

const PricingPage = () => (
  <Layout wide>

    <Helmet>
      <title>EchoWave Pricing</title>
      <meta name="description"
            content="Our simple startup pricing for EchoWave video conversion; Start creating wave animations and more."/>
      <script type="application/ld+json">{`
                {
                    "@context": "http://schema.org",
                    "@type": "BreadcrumbList",
                    "itemListElement": [
                        {
                            "@type": "ListItem",
                            "position": 1,
                            "name": "Pricing",
                            "item": "https://echowave.io/pricing/"
                        }
                    ]
                }`}
      </script>

    </Helmet>

    <div className={styles.container}>
      <h1 className={styles.title}>Stop gambling listeners can find you</h1>
      <h2 className={styles.blurb}>Start promoting your audio on Social Media, by <strong>converting your audio to video</strong> now!</h2>
    </div>

    <div className={styles.freeContainer}>

      <div className={styles.freePlan}>
        <h2>From <span>$18</span><sub>/month</sub></h2>
        <p>⚡ All plans include <strong>unlimited</strong> videos ⚡</p>
        <a href="https://app.echowave.io/register">
          <button className={styles.getStarted}>Create Free Account →</button>
        </a>
        <br/>
        <sub>For personal use only. Please contact us for Business Pricing</sub>
      </div>


      <section className={styles.features}>


        <div className={styles.feature}>
          <img className={styles.feature__img} src={audioWave} alt="Create picle perfect animation"/>
          <div className={styles.feature__title}>Wave Animations</div>
          <p className={styles.feature__p}>Make you video stand out on Instagram feeds by adding custom wave animations.</p>
        </div>

        <div className={styles.feature}>
          <img className={styles.feature__img} src={support} alt="EchoWave support logo"/>
          <div className={styles.feature__title}>Unmatched Support</div>
          <p className={styles.feature__p}>We are here to help, always ready to answer your questions and provide support.</p>
        </div>

        <div className={styles.feature}>
          <img className={styles.feature__img} src={infinity} alt="EchoWave support logo"/>
          <div className={styles.feature__title}>On Demand</div>
          <p className={styles.feature__p}>Create as many videos as you need, we don't set any limits here.</p>
        </div>

        <div className={styles.feature}>
          <img className={styles.feature__img} src={history} alt="Time machine icon"/>
          <div className={styles.feature__title}>Full length</div>
          <p className={styles.feature__p}>Choose your desired video duration, from a couple of seconds clip to your whole podcast.</p>
        </div>

        <div className={styles.feature}>
          <img className={styles.feature__img} src={handshake} alt="Handshake Guarantee"/>
          <div className={styles.feature__title}>Happiness Guarantee</div>
          <p className={styles.feature__p}>If you are not happy, we are not happy. We guarantee to do our best to make
            it right</p>
        </div>

        <div className={styles.feature}>
          <img className={styles.feature__img} src={data} alt="EchoWave download logo"/>
          <div className={styles.feature__title}>Download as .mp4</div>
          <p className={styles.feature__p}>Export your animations as a MP4 video files to share on any platform you choose</p>
        </div>


      </section>


      <GetStartedOrChat cta='Need an different plan, or have a question?'/>



    </div>

    <section className={styles.wrap}>
      <h2>Frequently Asked Questions</h2>
      <FAQ items={[
        {
          'question': 'What is your refund policy?',
          'answer': 'If you are not happy, then we want to make it right let us know how we can help.'
        },
        {
          'question': 'Can I cancel my Subscription?',
          'answer': 'Yes, you can cancel your subscription at any time, Just send us a message and we will terminate your contract within 48 hours.'
        },
        {
          'question': ' How long are generated videos kept?',
          'answer': 'While we are in beta, we store videos indefinitely, or until you tell us to remove them. As we scale we are looking to keep videos available for 6 months after the date created.'
        },
        {
          'question': 'Is my payment information secure?',
          'answer': 'Yes, we never directly see your credit card details; instead, we use the industry-standard stripe for payment processing (PCI Service Provider Level 1). For more information, please see the Stripe docs'
        },
        {
          'question': 'Do you offer discounted pricing?',
          'answer': 'We don\'t have any discount in place, however, if you deserve a discount let us know why via chat and we will help come to an arrangement'
        }
      ]} />





    </section>

  </Layout>
)

export default PricingPage
